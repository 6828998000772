import { storage, firestore, auth, googleAuthProvider } from '../firebase';
import { ref, uploadBytes, getDownloadURL, deleteObject } from 'firebase/storage';
import { addDoc, collection, getDocs, doc, deleteDoc, updateDoc } from 'firebase/firestore';

// Fungsi untuk mengambil file dari koleksi 'galeri'
export const fetchFiles = async () => {
  const querySnapshot = await getDocs(collection(firestore, 'galeri'));
  const filesArray = [];
  querySnapshot.forEach((doc) => {
    filesArray.push({ ...doc.data(), id: doc.id });
  });
  return filesArray;
};

// Fungsi untuk mengunggah file ke Firebase Storage dan menambahkannya ke koleksi 'galeri'
export const uploadFile = async (file, fileName, fileCategory, currentUser) => {
  const storageRef = ref(storage, `galeri/${file.name}`);
  await uploadBytes(storageRef, file);
  const fileURL = await getDownloadURL(storageRef);

  await addDoc(collection(firestore, 'galeri'), {
    name: fileName,
    url: fileURL,
    category: fileCategory,
    uploadedAt: new Date(),
    user: currentUser.email,
  });
};

// Fungsi untuk memperbarui nama file di koleksi 'galeri'
export const updateFileName = async (fileId, newName) => {
  await updateDoc(doc(firestore, 'galeri', fileId), {
    name: newName,
  });
};

// Fungsi untuk menghapus file dari Firebase Storage dan koleksi 'galeri'
export const deleteFile = async (fileId, fileUrl) => {
  await deleteDoc(doc(firestore, 'galeri', fileId));
  const storageRef = ref(storage, fileUrl);
  await deleteObject(storageRef);
};

// Fungsi untuk mengambil produk dari koleksi 'produk'
export const fetchProduk = async () => {
  try {
    const produkRef = firestore.collection('produk');
    const snapshot = await produkRef.get();
    return snapshot.docs.map(doc => ({
      id: doc.id,
      ...doc.data(),
    }));
  } catch (error) {
    console.error('Error fetching produk: ', error);
    throw error;
  }
};

// Fungsi untuk menangani perubahan status autentikasi
export const onAuthStateChanged = (callback) => {
  return auth.onAuthStateChanged(async (userAuth) => {
    if (userAuth) {
      const userRef = firestore.collection('users').doc(userAuth.uid);
      const snapshot = await userRef.get();
      let user = null;
      if (snapshot.exists) {
        user = snapshot.data();
      } else {
        user = {
          displayName: userAuth.displayName,
          email: userAuth.email,
          photoURL: userAuth.photoURL,
        };
      }
      callback(user, userAuth.uid);
    } else {
      callback(null, null);
    }
  });
};

// Fungsi untuk masuk menggunakan Google
export const signInWithGoogle = async () => {
  const result = await auth.signInWithPopup(googleAuthProvider);
  const { user } = result;
  if (user) {
    const userRef = firestore.collection('users').doc(user.uid);
    const snapshot = await userRef.get();
    if (!snapshot.exists) {
      await userRef.set({
        displayName: user.displayName,
        email: user.email,
        photoURL: user.photoURL,
        role: 'user',
      });
    }
  }
  return user;
};

// Fungsi untuk keluar dari sesi autentikasi
export const signOut = async () => {
  await auth.signOut();
};

// Fungsi untuk mengambil jumlah item di keranjang pengguna
export const getCartItemCount = async (userId) => {
  const cartQuery = firestore.collection('keranjang').where('userId', '==', userId);
  const cartSnapshot = await cartQuery.get();
  return cartSnapshot.size;
};

// Fungsi untuk mengunggah array gambar ke Firebase Storage dan mengembalikan array URL
export const uploadImages = async (images) => {
  const imageURLs = [];
  const storageRef = storage.ref();

  await Promise.all(images.map(async (file) => {
    const fileRef = storageRef.child(file.name);
    await fileRef.put(file);
    const imageURL = await fileRef.getDownloadURL();
    imageURLs.push(imageURL);
  }));

  return imageURLs;
};

// Fungsi untuk menambahkan produk baru ke Firestore
export const addProduct = async (productData) => {
  await firestore.collection('produk').add(productData);
};

// Fungsi untuk mengambil daftar produk dari Firestore
export const fetchProducts = async () => {
  const snapshot = await firestore.collection('produk').get();
  const products = snapshot.docs.map(doc => ({
    id: doc.id,
    ...doc.data()
  }));
  return products;
};

export const uploadImageBanner = async (images) => {
    const imageURLs = [];
    const storageRef = storage.ref();
  
    await Promise.all(images.map(async (file) => {
      const fileRef = storageRef.child(`banners/${file.name}`);
      await fileRef.put(file);
      const imageURL = await fileRef.getDownloadURL();
      imageURLs.push(imageURL);
    }));
  
    return imageURLs;
  };
  
  // Fungsi untuk menambahkan banner baru ke Firestore
  export const addBanner = async (bannerData) => {
    await addDoc(collection(firestore, 'banners'), bannerData);
  };
  
  // Fungsi untuk mengambil daftar banner dari Firestore
  export const fetchBanners = async () => {
    const querySnapshot = await getDocs(collection(firestore, 'banners'));
    const bannersArray = [];
    querySnapshot.forEach((doc) => {
      bannersArray.push({ ...doc.data(), id: doc.id });
    });
    return bannersArray;
  };
  
  export const deleteBanner = async (bannerId) => {
    try {
      await firestore.collection('banners').doc(bannerId).delete();
      console.log('Banner deleted successfully');
    } catch (error) {
      console.error('Error deleting banner:', error);
      throw error;
    }
  };