import React, { useState, useEffect } from 'react';
import { Button, Form, Container, Row, Col, Card, Spinner, Modal } from 'react-bootstrap';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../contexts/AuthContext';
import {
  fetchFiles,
  uploadFile,
  updateFileName,
  deleteFile
} from '../services/database';

const Galeri = () => {
  const { currentUser } = useAuth();
  const navigate = useNavigate();

  const [files, setFiles] = useState([]);
  const [file, setFile] = useState(null);
  const [fileName, setFileName] = useState('');
  const [fileCategory, setFileCategory] = useState('trainer smk otomotif');
  const [filePreview, setFilePreview] = useState(null);
  const [selectedCategory, setSelectedCategory] = useState('all');
  const [searchQuery, setSearchQuery] = useState('');
  const [loading, setLoading] = useState(false);
  const [showUploadForm, setShowUploadForm] = useState(false);
  const [showRenameModal, setShowRenameModal] = useState(false);
  const [renameFileId, setRenameFileId] = useState(null);
  const [renameFileName, setRenameFileName] = useState('');

  useEffect(() => {
    if (!currentUser) {
      navigate('/');
    } else if (currentUser.role !== 'admin' && currentUser.role !== 'karyawan') {
      navigate('/');
    } else {
      handleFetchFiles();
    }
  }, [currentUser, navigate]);

  const handleFetchFiles = async () => {
    const filesArray = await fetchFiles();
    setFiles(filesArray);
  };

  const handleFileChange = (e) => {
    const selectedFile = e.target.files[0];
    if (selectedFile) {
      setFile(selectedFile);
      const fileURL = URL.createObjectURL(selectedFile);
      setFilePreview(fileURL);
    }
  };

  const handleUpload = async (e) => {
    e.preventDefault();
    if (!file) return;

    setLoading(true);

    try {
      await uploadFile(file, fileName, fileCategory, currentUser);
      toast.success('File uploaded successfully!');
      handleFetchFiles();
    } catch (error) {
      toast.error('Failed to upload file');
    } finally {
      setLoading(false);
      setShowUploadForm(false);
      setFile(null);
      setFileName('');
      setFileCategory('trainer smk otomotif');
      setFilePreview(null);
    }
  };

  const handleOpenRenameModal = (id, name) => {
    setRenameFileId(id);
    setRenameFileName(name);
    setShowRenameModal(true);
  };

  const handleCloseRenameModal = () => {
    setRenameFileId(null);
    setRenameFileName('');
    setShowRenameModal(false);
  };

  const handleUpdateFileName = async () => {
    try {
      await updateFileName(renameFileId, renameFileName);
      toast.success('Nama file diperbarui!');
      handleFetchFiles();
    } catch (error) {
      toast.error('Gagal memperbarui nama file. Silakan coba lagi.');
    } finally {
      handleCloseRenameModal();
    }
  };

  const handleDeleteFile = async (id, url) => {
    try {
      await deleteFile(id, url);
      setFiles((prevFiles) => prevFiles.filter((file) => file.id !== id));
      toast.success('File berhasil dihapus!');
    } catch (error) {
      toast.error('Gagal menghapus file. Silakan coba lagi.');
    }
  };

  const filteredFiles = files.filter((file) => {
    const matchesCategory =
      selectedCategory === 'all' || file.category === selectedCategory || file.user === selectedCategory;
    const matchesSearch = file.name.toLowerCase().includes(searchQuery.toLowerCase());
    return matchesCategory && matchesSearch;
  });

  const openImageInNewTab = (url) => {
    window.open(url, '_blank');
  };

  return (
    <Container className="mt-4">
      <ToastContainer />
      <h1>Galeri</h1>
      {currentUser && (
        <>
          <Button
            variant="primary"
            onClick={() => setShowUploadForm(!showUploadForm)}
            className="mb-3"
          >
            {showUploadForm ? 'Batal Upload' : 'Upload File'}
          </Button>
          {showUploadForm && (
            <Card className="mb-4">
              <Card.Body>
                <Form onSubmit={handleUpload}>
                  <Form.Group controlId="formFile" className="mb-3">
                    <Form.Label>Upload file</Form.Label>
                    <Form.Control type="file" onChange={handleFileChange} />
                  </Form.Group>
                  {filePreview && (
                    <div className="mb-3">
                      {file && file.type.includes('video') ? (
                        <video src={filePreview} controls width="100%" />
                      ) : (
                        <img
                          src={filePreview}
                          alt="Preview"
                          style={{ width: '100%', height: '200px', objectFit: 'cover', cursor: 'pointer' }}
                          onClick={() => openImageInNewTab(filePreview)}
                        />
                      )}
                    </div>
                  )}
                  <Form.Group controlId="formFileName" className="mb-3">
                    <Form.Label>Nama File</Form.Label>
                    <Form.Control
                      type="text"
                      value={fileName}
                      onChange={(e) => setFileName(e.target.value)}
                      required
                    />
                  </Form.Group>
                  <Form.Group controlId="formFileCategory" className="mb-3">
                    <Form.Label>Kategori</Form.Label>
                    <Form.Control
                      as="select"
                      value={fileCategory}
                      onChange={(e) => setFileCategory(e.target.value)}
                      required
                    >
                      <option value="trainer smk otomotif">Trainer SMK Otomotif</option>
                      <option value="elektro">Elektro</option>
                      <option value="microcontroller">Microcontroller</option>
                      <option value="robotik">Robotik</option>
                      <option value="programming">Programming</option>
                      <option value="lainnya">Lainnya</option>
                    </Form.Control>
                  </Form.Group>
                  <Button variant="primary" type="submit" disabled={loading}>
                    {loading ? <Spinner animation="border" size="sm" /> : 'Upload'}
                  </Button>
                </Form>
              </Card.Body>
            </Card>
          )}
        </>
      )}
      <Form.Group controlId="formCategoryFilter" className="mb-3">
        <Form.Label>Filter Kategori</Form.Label>
        <Form.Control
          as="select"
          value={selectedCategory}
          onChange={(e) => setSelectedCategory(e.target.value)}
        >
          <option value="all">Semua Kategori</option>
          <option value="latest">Terbaru</option>
          <option value="trainer smk otomotif">Trainer SMK Otomotif</option>
          <option value="elektro">Elektro</option>
          <option value="microcontroller">Microcontroller</option>
          <option value="robotik">Robotik</option>
          <option value="programming">Programming</option>
          <option value="lainnya">Lainnya</option>
          {currentUser && <option value={currentUser.email}>Diunggah oleh Saya</option>}
        </Form.Control>
      </Form.Group>
      <Form.Group controlId="formSearchQuery" className="mb-3">
        <Form.Label>Cari File</Form.Label>
        <Form.Control
          type="text"
          placeholder="Masukkan kata kunci"
          value={searchQuery}
          onChange={(e) => setSearchQuery(e.target.value)}
        />
      </Form.Group>
      <Row xs={2} md={3} lg={4} className="g-4">
        {filteredFiles.map((file) => (
          <Col key={file.id}>
            <Card style={{ width: '100%' }}>
              {file.url.includes('video') ? (
                <video src={file.url} controls className="card-img-top" style={{ width: '100%' }} />
              ) : (
                <Card.Img
                  variant="top"
                  src={file.url}
                  alt={file.name}
                  style={{ width: '100%', height: '200px', objectFit: 'cover' }}
                />
              )}
              <Card.Body>
                <Card.Title>{file.name}</Card.Title>
                <Card.Text>
                  Kategori: {file.category} <br />
                  Diunggah oleh: {file.user} <br />
                  Diunggah pada: {new Date(file.uploadedAt.toDate()).toLocaleString()}
                </Card.Text>
                {(currentUser && currentUser.role === 'admin') || file.user === currentUser.email ? (
                  <>
                    <Button
                      variant="warning"
                      className="me-2"
                      onClick={() => handleOpenRenameModal(file.id, file.name)}
                    >
                      Ubah Nama
                    </Button>
                    <Button
                      variant="danger"
                      onClick={() => handleDeleteFile(file.id, file.url)}
                    >
                      Hapus
                    </Button>
                  </>
                ) : null}
              </Card.Body>
            </Card>
          </Col>
        ))}
      </Row>
      <Modal show={showRenameModal} onHide={handleCloseRenameModal}>
        <Modal.Header closeButton>
          <Modal.Title>Ubah Nama File</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form.Group controlId="formRenameFileName" className="mb-3">
            <Form.Label>Nama File Baru</Form.Label>
            <Form.Control
              type="text"
              value={renameFileName}
              onChange={(e) => setRenameFileName(e.target.value)}
              required
            />
          </Form.Group>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseRenameModal}>
            Batal
          </Button>
          <Button variant="primary" onClick={handleUpdateFileName}>
            Simpan Perubahan
          </Button>
        </Modal.Footer>
      </Modal>
    </Container>
  );
};

export default Galeri;
