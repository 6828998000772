// src/components/Produk.js
import React, { useEffect, useState } from 'react';
import { Container, Row, Col, Card, Dropdown, Carousel, Form } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import './Produk.css';
import { fetchProduk, fetchBanners } from '../services/database';

const Produk = () => {
  const [produk, setProduk] = useState([]);
  const [kategori, setKategori] = useState('Semua');
  const [searchTerm, setSearchTerm] = useState('');
  const [suggestions, setSuggestions] = useState([]);
  const [banners, setBanners] = useState([]);

  useEffect(() => {
    const getProduk = async () => {
      try {
        const produkList = await fetchProduk();
        setProduk(produkList);
      } catch (error) {
        console.error('Error fetching produk: ', error);
      }
    };

    const getBanners = async () => {
      try {
        const bannersList = await fetchBanners();
        setBanners(bannersList);
      } catch (error) {
        console.error('Error fetching banners: ', error);
      }
    };

    getProduk();
    getBanners();
  }, []);

  const handleKategoriChange = (kategori) => {
    setKategori(kategori);
  };

  const handleSearchChange = (event) => {
    const value = event.target.value;
    setSearchTerm(value);

    if (value) {
      const searchSuggestions = produk
        .filter((item) => item.nama.toLowerCase().includes(value.toLowerCase()))
        .map((item) => item.nama);
      setSuggestions(searchSuggestions);
    } else {
      setSuggestions([]);
    }
  };

  const handleSuggestionClick = (suggestion) => {
    setSearchTerm(suggestion);
    setSuggestions([]);
  };

  const handleLihatDetail = (id) => {
    return (
      <Link to={`/produk/${id}`} className="btn btn-primary btn-sm mt-auto">
        Lihat Detail
      </Link>
    );
  };

  const filteredProduk = produk.filter((item) => {
    const matchKategori = kategori === 'Semua' || item.kategori === kategori;
    const matchSearch = item.nama.toLowerCase().includes(searchTerm.toLowerCase());
    return matchKategori && matchSearch;
  });

  return (
    <Container className="mt-4">
      <div className="banner-container mb-4">
        <Carousel>
          {banners.map((banner, index) => (
            <Carousel.Item key={index}>
              <img
                className="d-block w-100"
                src={banner.url}
                alt={`Banner ${index + 1}`}
                style={{ width: '600px', height: '180px', objectFit: 'cover' }}
              />
              <Carousel.Caption>
                <h3>{banner.title}</h3>
                <p>{banner.description}</p>
              </Carousel.Caption>
            </Carousel.Item>
          ))}
        </Carousel>
      </div>

      <div className="mb-4">
        <Dropdown className="mr-2">
          <Dropdown.Toggle variant="outline-primary" id="dropdown-kategori">
            Kategori: {kategori}
          </Dropdown.Toggle>
          <Dropdown.Menu>
            <Dropdown.Item onClick={() => handleKategoriChange('Semua')}>Semua</Dropdown.Item>
            <Dropdown.Item onClick={() => handleKategoriChange('Mikrokontroler')}>Mikrokontroler</Dropdown.Item>
            <Dropdown.Item onClick={() => handleKategoriChange('IoT')}>IoT</Dropdown.Item>
            <Dropdown.Item onClick={() => handleKategoriChange('Sensor')}>Sensor</Dropdown.Item>
            <Dropdown.Divider />
            <Dropdown.Header>Trainer SMK</Dropdown.Header>
            <Dropdown.Item onClick={() => handleKategoriChange('Trainer SMK Otomotif')}>
              Trainer Otomotif
            </Dropdown.Item>
            <Dropdown.Item onClick={() => handleKategoriChange('Trainer SMK Elektronika')}>
              Trainer Elektronika
            </Dropdown.Item>
            <Dropdown.Item onClick={() => handleKategoriChange('Trainer SMK Listrik')}>
              Trainer Listrik
            </Dropdown.Item>
            <Dropdown.Item onClick={() => handleKategoriChange('Trainer SMK Mikrokontroler')}>
              Trainer Mikrokontroler
            </Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown>

        <Form.Control
          type="text"
          placeholder="Cari produk..."
          value={searchTerm}
          onChange={handleSearchChange}
          className="mt-2"
        />

        {suggestions.length > 0 && (
          <ul className="suggestions-list">
            {suggestions.map((suggestion, index) => (
              <li key={index} onClick={() => handleSuggestionClick(suggestion)}>
                {suggestion}
              </li>
            ))}
          </ul>
        )}
      </div>

      <Row xs={2} sm={3} md={4} lg={5} className="row-cols-1 row-cols-md-2 g-2">
        {filteredProduk.map((item) => (
          <Col key={item.id} className="mb-4">
            <Card style={{ padding: '1px', margin: 'auto', minHeight: '350px' }}>
              <div className="product-image-container">
                <Card.Img
                  variant="top"
                  src={item.imageURLs[0]}
                  className="product-image"
                  style={{ height: '250px', objectFit: 'contain' }}
                />
              </div>
              <Card.Body style={{ height: '100%' }}>
                <Card.Title style={{ fontSize: '13px', minHeight: '3rem', color: '#333', fontWeight: 'bold' }}>
                  {item.nama}
                </Card.Title>
                <Card.Text style={{ color: '#666', marginBottom: 'auto' }}></Card.Text>
                <Card.Text style={{ color: '#black', fontWeight: 'bold', fontSize: '13px' }}>
                  Harga: Rp. {item.harga}
                </Card.Text>
                {handleLihatDetail(item.id)}
              </Card.Body>
            </Card>
          </Col>
        ))}
      </Row>
    </Container>
  );
};

export default Produk;
