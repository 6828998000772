import React, { useState, useEffect } from 'react';
import { Form, Button, Spinner, Toast, Container } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../contexts/AuthContext';
import { uploadImages, fetchBanners, addBanner, deleteBanner } from '../services/database'; // Import fungsi dari firebaseService.js

const AdminBanner = () => {
  const { currentUser } = useAuth();
  const navigate = useNavigate();

  const [gambar, setGambar] = useState([]); // State untuk menyimpan file gambar yang dipilih
  const [previewGambar, setPreviewGambar] = useState([]); // State untuk menyimpan preview gambar
  const [loading, setLoading] = useState(false); // State untuk menunjukkan loading
  const [showToast, setShowToast] = useState(false); // State untuk menunjukkan Toast
  const [toastMessage, setToastMessage] = useState(''); // Pesan yang ditampilkan di Toast
  const [banners, setBanners] = useState([]); // State untuk menyimpan daftar banner

  useEffect(() => {
    const fetchData = async () => {
      try {
        const bannersData = await fetchBanners();
        setBanners(bannersData);
      } catch (error) {
        console.error('Error fetching banners: ', error);
      }
    };

    if (currentUser && currentUser.role === 'admin') {
      fetchData();
    } else {
      navigate('/'); // Redirect ke halaman utama jika bukan admin
    }
  }, [currentUser, navigate]);

  // Mengubah gambar yang dipilih menjadi URL preview
  const handleGambarChange = (e) => {
    const files = Array.from(e.target.files);
    setGambar([...gambar, ...files]); // Menggabungkan gambar yang baru dipilih dengan yang sudah ada

    const previews = files.map(file => URL.createObjectURL(file));
    setPreviewGambar([...previewGambar, ...previews]); // Menggabungkan preview gambar yang baru dengan yang sudah ada
  };

  // Menghapus gambar dari preview
  const handleHapusGambar = (index) => {
    const updatedGambar = [...gambar];
    const updatedPreviewGambar = [...previewGambar];

    updatedGambar.splice(index, 1);
    updatedPreviewGambar.splice(index, 1);

    setGambar(updatedGambar);
    setPreviewGambar(updatedPreviewGambar);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      setLoading(true); // Mulai loading

      if (gambar.length === 0) {
        setToastMessage('Mohon pilih gambar!');
        setShowToast(true);
        setLoading(false); // Hentikan loading jika ada error
        return;
      }

      // Unggah gambar dan dapatkan URL-nya
      const imageURLs = await uploadImages(gambar);

      // Simpan data banner ke Firestore
      for (const url of imageURLs) {
        await addBanner({
          url,
          uploadedAt: new Date(),
        });
      }

      // Reset form setelah submit berhasil
      setGambar([]);
      setPreviewGambar([]); // Reset preview gambar
      setLoading(false); // Selesai loading setelah submit berhasil
      setToastMessage('Banner berhasil ditambahkan!');
      setShowToast(true); // Tampilkan Toast sukses

      // Fetch ulang daftar banner setelah menambahkan banner baru
      const bannersData = await fetchBanners();
      setBanners(bannersData);
    } catch (error) {
      console.error('Error adding banner: ', error);
      setToastMessage('Gagal menambahkan banner. Silakan coba lagi.');
      setShowToast(true); // Tampilkan Toast error
      setLoading(false); // Selesai loading jika ada error
    }
  };

  const handleDeleteBanner = async (id) => {
    try {
      setLoading(true); // Mulai loading

      await deleteBanner(id);

      setLoading(false); // Selesai loading setelah berhasil menghapus
      setToastMessage('Banner berhasil dihapus!');
      setShowToast(true); // Tampilkan Toast sukses

      // Fetch ulang daftar banner setelah menghapus banner
      const bannersData = await fetchBanners();
      setBanners(bannersData);
    } catch (error) {
      console.error('Error deleting banner: ', error);
      setToastMessage('Gagal menghapus banner. Silakan coba lagi.');
      setShowToast(true); // Tampilkan Toast error
      setLoading(false); // Selesai loading jika ada error
    }
  };

  if (!currentUser || currentUser.role !== 'admin') {
    return <Container className="mt-5">Loading...</Container>; // Tampilkan loading atau pesan lain sesuai kebutuhan
  }

  return (
    <div className="mt-4">
      <h2>Admin Banner</h2>

      {/* Toast untuk menampilkan pesan sukses atau error */}
      <Toast show={showToast} onClose={() => setShowToast(false)} delay={3000} autohide>
        <Toast.Header>
          <strong className="mr-auto">Notifikasi</strong>
        </Toast.Header>
        <Toast.Body>{toastMessage}</Toast.Body>
      </Toast>

      <Form onSubmit={handleSubmit}>
        <Form.Group controlId="gambarBanner">
          <Form.Label>Gambar Banner</Form.Label>
          <Form.Control type="file" onChange={handleGambarChange} multiple required />
          <div className="mt-2">
            {previewGambar.map((preview, index) => (
              <div key={index} className="mb-2">
                <img
                  src={preview}
                  alt={`Preview ${index}`}
                  className="img-preview mr-2"
                  style={{ maxWidth: '200px', maxHeight: '200px' }} // Contoh pengaturan ukuran gambar
                />
                <Button variant="danger" size="sm" onClick={() => handleHapusGambar(index)}>Hapus</Button>
              </div>
            ))}
          </div>
        </Form.Group>

        <Button variant="primary" type="submit">
          {loading ? (
            <>
              <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" />
              <span className="ml-2">Menyimpan...</span>
            </>
          ) : (
            'Simpan Banner'
          )}
        </Button>
      </Form>

      <div className="mt-4">
        <h3>Daftar Banner</h3>
        {banners.map((banner, index) => (
          <div key={banner.id} className="mb-3">
            <img
              src={banner.url}
              alt={`Banner ${index}`}
              className="img-banner mr-2"
              style={{ maxWidth: '200px', maxHeight: '200px' }} // Contoh pengaturan ukuran gambar
            />
            <Button variant="danger" size="sm" onClick={() => handleDeleteBanner(banner.id)}>Hapus</Button>
          </div>
        ))}
      </div>
    </div>
  );
};

export default AdminBanner;
