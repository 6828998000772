import React, { useState, useEffect } from 'react';
import { Container, Row, Col, Card, Button, Toast, Form, Carousel } from 'react-bootstrap';
import { firestore, storage } from '../firebase'; // Sesuaikan path dengan lokasi file firebase.js
import { useAuth } from '../contexts/AuthContext'; // Pastikan path sesuai dengan lokasi file AuthContext.js
import { useNavigate } from 'react-router-dom'; // Menggunakan useNavigate

const ListProduk = () => {
  const [produkList, setProdukList] = useState([]);
  const { currentUser } = useAuth(); // Dapatkan pengguna saat ini
  const navigate = useNavigate(); // Gantikan useHistory dengan useNavigate

  const [showToast, setShowToast] = useState(false);
  const [toastMessage, setToastMessage] = useState('');
  const [toastType, setToastType] = useState('success'); // Default type adalah success

  const [editProduk, setEditProduk] = useState({ id: '', nama: '', deskripsi: '', harga: 0, imageURLs: [] });
  const [selectedImages, setSelectedImages] = useState([]);
  const [previewGambar, setPreviewGambar] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const snapshot = await firestore.collection('produk').get();
        const produkData = snapshot.docs.map(doc => ({
          id: doc.id,
          ...doc.data()
        }));
        setProdukList(produkData);
      } catch (error) {
        console.error('Error fetching products: ', error);
      }
    };

    if (currentUser) {
      fetchData();
    }
  }, [currentUser]);

  // Redirect pengguna jika tidak ada currentUser atau bukan admin
  useEffect(() => {
    if (!currentUser) {
      navigate('/'); // Redirect ke halaman login jika tidak ada pengguna
    } else if (currentUser.role !== 'admin') {
      navigate('/'); // Redirect ke halaman tidak berizin jika bukan admin
    }
  }, [currentUser, navigate]);

  const handleDeleteProduk = async (id, imageURLs) => {
    try {
      // Hapus produk dari Firestore
      await firestore.collection('produk').doc(id).delete();

      // Hapus gambar dari Firebase Storage (opsional, tergantung kebutuhan)
      if (imageURLs) {
        for (const url of imageURLs) {
          const storageRef = storage.refFromURL(url);
          await storageRef.delete();
        }
      }

      // Update state untuk memperbarui daftar produk setelah penghapusan
      setProdukList(prevList => prevList.filter(produk => produk.id !== id));
      setToastType('success');
      setToastMessage('Produk berhasil dihapus!');
      setShowToast(true);
    } catch (error) {
      console.error('Error deleting product: ', error);
      setToastType('error');
      setToastMessage('Gagal menghapus produk. Silakan coba lagi.');
      setShowToast(true);
    }
  };

  const handleEditProduk = async () => {
    try {
      const { id, nama, deskripsi, harga, imageURLs } = editProduk;
      
      // Update produk di Firestore
      await firestore.collection('produk').doc(id).update({
        nama,
        deskripsi,
        harga,
        imageURLs
      });

      // Jika ada gambar yang dipilih untuk diunggah, unggah ke Firebase Storage
      if (selectedImages.length > 0) {
        const uploadTasks = selectedImages.map(image => {
          const imageRef = storage.ref().child(`produk/${image.name}`);
          return imageRef.put(image);
        });
        await Promise.all(uploadTasks);

        // Dapatkan URL gambar yang diunggah
        const uploadedImageURLs = await Promise.all(uploadTasks.map(uploadTask => uploadTask.snapshot.ref.getDownloadURL()));

        // Gabungkan URL gambar baru dengan imageURLs yang sudah ada
        const updatedImageURLs = [...imageURLs, ...uploadedImageURLs];
        setEditProduk(prevState => ({ ...prevState, imageURLs: updatedImageURLs }));
      }

      // Update state untuk memperbarui daftar produk setelah edit
      setProdukList(prevList =>
        prevList.map(produk => (produk.id === id ? { ...produk, nama, deskripsi, harga, imageURLs } : produk))
      );

      setToastType('success');
      setToastMessage('Produk berhasil diperbarui!');
      setShowToast(true);
      setEditProduk({ id: '', nama: '', deskripsi: '', harga: 0, imageURLs: [] }); // Reset form edit produk
      setSelectedImages([]); // Reset selectedImages setelah upload
      setPreviewGambar([]); // Reset previewGambar setelah upload
    } catch (error) {
      console.error('Error updating product: ', error);
      setToastType('error');
      setToastMessage('Gagal memperbarui produk. Silakan coba lagi.');
      setShowToast(true);
    }
  };

  const handleImageChange = (e) => {
    if (e.target.files && e.target.files.length > 0) {
      const files = Array.from(e.target.files);

      // Simpan file yang akan diunggah ke state selectedImages
      setSelectedImages(prevImages => [...prevImages, ...files]);

      // Buat pratinjau gambar untuk ditampilkan
      const previews = files.map(file => URL.createObjectURL(file));
      setPreviewGambar(prevPreviews => [...prevPreviews, ...previews]);
    }
  };

  return (
    <Container className="mt-4">
      <h2>Daftar Produk</h2>

      {/* Toast untuk menampilkan pesan sukses atau error */}
      <Toast show={showToast} onClose={() => setShowToast(false)} delay={3000} autohide bg={toastType === 'success' ? 'success' : 'danger'}>
        <Toast.Header>
          <strong className="mr-auto">Notifikasi</strong>
        </Toast.Header>
        <Toast.Body>{toastMessage}</Toast.Body>
      </Toast>

      <Row>
        {produkList.map(produk => (
          <Col key={produk.id} sm={6} md={4} lg={3} className="mb-4">
            <Card>
              <div className="product-image-container">
                {produk.imageURLs && produk.imageURLs.length > 0 && (
                  <Carousel>
                    {produk.imageURLs.map((url, index) => (
                      <Carousel.Item key={index}>
                        <img
                          className="d-block w-100"
                          src={url}
                          alt={`Slide ${index + 1}`}
                        />
                      </Carousel.Item>
                    ))}
                  </Carousel>
                )}
              </div>
              <Card.Body>
                <Card.Title>{produk.nama}</Card.Title>
                <Card.Text>{produk.deskripsi}</Card.Text>
                <Card.Text>Harga: ${produk.harga}</Card.Text>

                {/* Tombol untuk edit produk */}
                <Button variant="primary" onClick={() => setEditProduk({ id: produk.id, nama: produk.nama, deskripsi: produk.deskripsi, harga: produk.harga, imageURLs: produk.imageURLs })}>
                  Edit
                </Button>

                <Button variant="danger" onClick={() => handleDeleteProduk(produk.id, produk.imageURLs)} className="ml-2">
                  Hapus
                </Button>
              </Card.Body>
            </Card>

            {/* Form edit produk */}
            {editProduk.id === produk.id && (
              <Card className="mt-2">
                <Card.Body>
                  <Form.Group>
                    <Form.Label>Nama Produk</Form.Label>
                    <Form.Control type="text" value={editProduk.nama} onChange={e => setEditProduk({ ...editProduk, nama: e.target.value })} />
                  </Form.Group>
                  <Form.Group>
                    <Form.Label>Deskripsi Produk</Form.Label>
                    <Form.Control as="textarea" rows={3} value={editProduk.deskripsi} onChange={e => setEditProduk({ ...editProduk, deskripsi: e.target.value })} />
                  </Form.Group>
                  <Form.Group>
                    <Form.Label>Harga</Form.Label>
                    <Form.Control type="number" value={editProduk.harga} onChange={e => setEditProduk({ ...editProduk, harga: e.target.value })} />
                  </Form.Group>

                  {/* Input untuk memilih gambar baru */}
                  <Form.Group>
                    <Form.Label>Gambar Produk</Form.Label>
                    <Form.Control type="file" onChange={handleImageChange} multiple />
                    <div className="mt-2">
                      {previewGambar.map((preview, index) => (
                        <div key={index} className="mb-2">
                          <img
                            src={preview}
                            alt={`Preview ${index}`}
                            className="img-preview mr-2"
                            style={{ maxWidth: '200px', maxHeight: '200px' }} // Pengaturan ukuran gambar
                          />
                          <Button variant="danger" size="sm" onClick={() => {
                            setPreviewGambar(prevPreviews => prevPreviews.filter((_, idx) => idx !== index));
                            setSelectedImages(prevImages => prevImages.filter((_, idx) => idx !== index));
                          }}>Hapus</Button>
                        </div>
                      ))}
                    </div>
                  </Form.Group>

                  {/* Tombol untuk menyimpan perubahan atau batal */}
                  <Button variant="success" onClick={handleEditProduk}>
                    Simpan
                  </Button>
                  <Button variant="secondary" className="ml-2" onClick={() => setEditProduk({ id: '', nama: '', deskripsi: '', harga: 0, imageURLs: [] })}>
                    Batal
                  </Button>
                </Card.Body>
              </Card>
            )}
          </Col>
        ))}
      </Row>
    </Container>
  );
};

export default ListProduk;
